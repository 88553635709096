var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":_vm.$route.meta.name,"left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"order-list-container nav-page-container"},[_c('van-search',{attrs:{"shape":"round","show-action":"","placeholder":"请输入搜索关键词"},on:{"search":_vm.handleOnSearch},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{staticClass:"search-btn",on:{"click":_vm.handleOnSearch}},[_vm._v("搜索")])]},proxy:true}]),model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchName"}}),_c('div',{staticClass:"search-status-container"},_vm._l((_vm.statusSearchList),function(status){return _c('div',{key:status.name,class:{ checked: status.id === _vm.searchForm.status },on:{"click":function () {
          _vm.searchForm.status = status.id;
          _vm.onRefresh();
        }}},[_vm._v(" "+_vm._s(status.name)+" ")])}),0),_c('page-container',{ref:"pageContainer",staticClass:"order-list-scroller",attrs:{"scroll-top":_vm.scrollTop},on:{"update:scrollTop":function($event){_vm.scrollTop=$event},"update:scroll-top":function($event){_vm.scrollTop=$event}}},[_c('van-pull-refresh',{staticStyle:{"position":"relative","z-index":"1"},on:{"refresh":_vm.onRefresh},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.pagination.pageNum < 2 ? '' : '加载中...')+" ")]},proxy:true}]),model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.dataList),function(item){return _c('div',{staticClass:"order-info-item",on:{"click":function($event){return _vm.handleOrderDetail(item)}}},[_c('div',{staticClass:"agency-container"},[_c('span',{staticClass:"agency-name"}),_c('div',{staticClass:"order-status-name"},[_vm._v(_vm._s(_vm.getOrderStatusName(item.status)))])]),_c('goods-info',{attrs:{"view-model":"small","value":{
                goodsImgSrc: _vm.getFirstImg(item),
                goodsName: item.name,
                goodsDesc: item.subName,
              }},scopedSlots:_vm._u([{key:"price",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.priceAndIntegralText(item))}})]},proxy:true},{key:"price-right",fn:function(){return [_vm._v(" ×"+_vm._s(item.num)+" ")]},proxy:true}],null,true)}),_c('div',{staticClass:"btn-container"},[(_vm.getBtnShow('退换/售后', item.status))?_c('van-button',{attrs:{"size":"small","round":"","plain":"","color":"#666"},on:{"click":function($event){$event.stopPropagation();return _vm.handleApplyForReturn(item)}}},[_vm._v("退换/售后 ")]):_vm._e(),(_vm.getBtnShow('取消申诉', item.status))?_c('van-button',{attrs:{"size":"small","round":"","plain":"","color":"#666"},on:{"click":function($event){$event.stopPropagation();return _vm.handleCancelAdvice.apply(null, arguments)}}},[_vm._v("取消申诉 ")]):_vm._e(),(_vm.getBtnShow('查看物流', item.status))?_c('van-button',{attrs:{"size":"small","round":"","plain":"","color":"#666"},on:{"click":function($event){$event.stopPropagation();return _vm.handleExpressView(item)}}},[_vm._v("查看物流 ")]):_vm._e(),(_vm.getBtnShow('取消订单', item.status))?_c('van-button',{attrs:{"size":"small","round":"","plain":"","color":"#666"},on:{"click":function($event){$event.stopPropagation();return _vm.handleOnCancelOrder(item)}}},[_vm._v("取消订单 ")]):_vm._e(),(_vm.getBtnShow('去付款', item.status))?_c('van-button',{attrs:{"size":"small","round":"","plain":"","type":"primary"}},[_vm._v("去付款 ")]):_vm._e(),(_vm.getBtnShow('确认收货', item.status))?_c('van-button',{attrs:{"size":"small","round":"","type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleConfirmOrder(item)}}},[_vm._v("确认收货 ")]):_vm._e()],1)],1)}),0)],1)],1),(_vm.expressShow)?_c('van-overlay',{attrs:{"show":true,"lock-scroll":false},on:{"click":function () {
        !_vm.expressLoading && (this$1.expressShow = false)
      }}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.expressLoading),expression:"!expressLoading"}]},[_c('express-info',{attrs:{"order-id":_vm.expressOrderId,"loading":_vm.expressLoading,"service-model":"integral"},on:{"update:loading":function($event){_vm.expressLoading=$event},"change":function (data) {
            this$1.expressShow = !!data;
          }}})],1),_c('van-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.expressLoading),expression:"expressLoading"}]},[_vm._v("查询中...")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }